(function ($) {
  'use strict';

  var $body, state;
  function setCookie(c_name, value, exdays) {
    var exdate = new Date();
    exdate.setDate(exdate.getDate() + exdays);
    var c_value = window.escape(value) + (exdays === null ? "" : "; expires=" + exdate.toUTCString());
    document.cookie = c_name + "=" + c_value;
  }
  function getCookie(c_name) {
    var i,
      name,
      value,
      cookies = document.cookie.split(";");
    for (i = 0; i < cookies.length; i++) {
      name = cookies[i].substr(0, cookies[i].indexOf("="));
      value = cookies[i].substr(cookies[i].indexOf("=") + 1);
      name = name.replace(/^\s+|\s+$/g, "");
      if (name == c_name) {
        return window.unescape(value);
      }
    }
    return false;
  }
  window.DGS.OnLoad.getInstance().register(function () {
    var $spots = $spots || $('.component.alternative-url-spot'),
      $spot = $spots.length ? $($spots[0]) : null;
    $body = $('body');
    if ($spot) {
      $('.disable-alternative-url', $spot).click(function (event) {
        event.preventDefault();
        $spot.css('display', 'none');
        $spot.addClass('is-hidden');
        $body.css('margin-top', 0);
        setCookie("has-seen-alt-url", "true", 90);
      });
      if (getCookie("has-seen-alt-url")) {
        $spot.css('display', 'none');
        $spot.addClass('is-hidden');
      } else {
        window.DGS.Resizer.getInstance().register(function () {
          var isMobile = window.matchMedia("(max-width: 1024px)").matches;
          var cookieWarning = $(".cookie-warning");
          var isEmpty = cookieWarning.find(".component-content").children().length;
          if ($spot && !$spot.hasClass('is-hidden')) {
            if (state != "inHeader") {
              $spot.insertBefore($('#header', $body));
              state = "inHeader";
            }
            if (!isMobile) {
              $body.css('margin-top', $spot.outerHeight());
              if (isEmpty) {
                $spot.css({
                  'top': '0px',
                  'position': 'fixed'
                });
              } else {
                $spot.css('top', -$spot.outerHeight());
              }
            } else {
              $body.css('margin-top', 0);
              $spot.css('top', "auto");
            }
          }
        });
      }
    }
  });
})(jQuery);
require('core-js');require('regenerator-runtime/runtime');/* eslint-disable quotes */
Array.prototype.flat;
if (Array.prototype.flatten === undefined && Array.prototype.flat !== undefined) {
  Array.prototype.flatten = Array.prototype.flat;
}
// FOUNDATION
import $ from "jquery";
import { TweenMax } from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import ScrollMagic from "scrollmagic";
window.jQuery = $;
window.$ = $;
require("../../../wdh-foundation/dateformat/dateformat.js");
require("../../../wdh-foundation/dgs/dgs-api.js");
require("../../../wdh-foundation/dgs/dgs-events.js");
// require('@demant/megamenu');
require("../../../wdh-foundation/dgs-utils/dgs-utils.js");
require("../../../wdh-foundation/dgs-sharing/dgs-sharing.js");
// require('@demant/burgermenu');
require("@demant/legacy/googlemaps");
require("iframe-resizer");
require("jquery-nice-select/js/jquery.nice-select.min.js");
require("jquery-ui-dist/jquery-ui.min.js");
require("lightgallery/src/js/lightgallery.js");
require('@demant/legacy/lunametrics');
require("jquery-bridget");
require("masonry-layout");
require("moment");
require("scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap");

// FEATURE
require("../../medical/js/feature/component-banner-spot.js");
require("../../medical/js/feature/component-center-list-spot.js");
require("../../medical/js/feature/component-center-spot.js");
require("../../medical/js/feature/component-country-selector.js");
require("../../medical/js/feature/component-a-data-hierarchy.js");
require("../../medical/js/feature/component-b-data-hierarchy-appframe-component.js");
require("../../medical/js/feature/component-b-data-hierarchy-hubspot.js");
require("../../medical/js/feature/component-b-data-hierarchy-multi-step.js");
//require("./feature/component-b-data-hierarchy-resource-center.js");
require("../../medical/js/feature/component-data-hierarchy-dataset.js");
require("../../medical/js/feature/component-data-list-search-spot.js");
require("../../medical/js/feature/component-data-list-spot.js");
require("../../medical/js/feature/component-distributor-locator.js");
//require("./feature/component-download-center.js");
require("../../medical/js/feature/jquery-cycle.js");
require("../../medical/js/feature/component-iframe.js");
require("../../medical/js/feature/component-intro-banner.js");
require("../../medical/js/feature/component-job-list-spot.js");
require("../../medical/js/feature/component-lattice-spot.js");
require("../../medical/js/feature/component-leaving-notification.js");
require("../../medical/js/feature/component-link-list.js");
require("../../medical/js/feature/component-locations-spot/component-locations-spot.js");
require("../../medical/js/feature/component-option-spot.js");
require("../../medical/js/feature/component-resource-center/component-resource-center.js");
require("../../medical/js/feature/product-tool/colour-selector.js");
require("../../medical/js/feature/product-tool/hammer.js");
require("../../medical/js/feature/product-tool/jqueryhammer-full.js");
require("../../medical/js/feature/product-tool/knockout-310.js");
require("../../medical/js/feature/product-tool/model-selector.js");
require("../../medical/js/feature/component-richtext-submenu.js");
require("../../medical/js/feature/component-spot-control-spot.js");
require("../../medical/js/feature/component-submenu.js");
require("../../medical/js/feature/component-tile.js");
require("../../medical/js/feature/component-video-spot.js");
require("../../medical/js/feature/component-alternative-url.js");
require("../../medical/js/feature/component-box-spot.js");
require("../../medical/js/feature/component-color-selector-spot.js");
require("../../medical/js/feature/component-contact-information.js");
// require('./feature/component-cookies.js');

require("../../medical/js/feature/component-form");
require("../../medical/js/feature/component-hearing-center-locator.js");
require("../../medical/js/feature/component-iframe-us.js");

// require('./feature/custom-component-image-spot.js'); // old image-spot
require("../../medical/js/feature/component-image-spot/index.js"); // new image-spot

require("../../medical/js/feature/component-job-detail-spot.js");
require("../../medical/js/feature/language-selector-spot.js");
require("../../medical/js/feature/component-news-list-spot.js");
require("../../medical/js/feature/component-pairing-guide.js");
require("../../medical/js/feature/component-producttool.js");
require("../../medical/js/feature/component-profile-spot.js");
require("../../medical/js/feature/component-screening-test.js");
require("../../medical/js/feature/component-search.js");
require("../../medical/js/feature/component-tagged-list-spot.js");
require("../../medical/js/feature/component-testimonial-spot.js");
require("../../medical/js/feature/component-text-image-spot.js");
require("../../medical/js/feature/component-two-section-grid.js");
require("../../medical/js/feature/component-blog/blog-categories/component-blog-categories");
require("../../medical/js/feature/component-blog/blog-list/component-blog-list");
require("../../medical/js/feature/component-blog/blog-post/component-blog-post");
require("../../medical/js/feature/component-blog/blog-author/component-blog-author");
require("../../medical/js/feature/component-blog/blog-related/component-blog-related");
require("../../medical/js/feature/component-blog/blog-author-details/component-blog-author-details");
require("../../medical/js/feature/component-blog/author-related-blogs/component-author-related-blogs");
require("../../medical/js/feature/component-section-menu");

// PROJECT
require("../../medical/js/project/base-main-custom.js");
require("../../medical/js/project/tracking-gtm.js");
require("../../medical/js/project/custom-contact-float.js");
require("../../medical/js/project/custom-datepicker.js");
require("../../medical/js/project/custom-flowtype.js");
require("../../medical/js/project/custom-footer.js");
require("../../medical/js/project/custom-newslist.js");
require("../../medical/js/project/custom-sharing.js");
require("../../medical/js/project/custom-to-top.js");
require("../../medical/js/project/flowtype.js");
require("../../medical/js/project/main.js");
require('@demant/wdh-gdpr/lib/default.implementation.js');
(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    $('#wrapper .component.pairing-guide').each(function () {
      var $this = $(this),
        $select = $('select', $this);
      $select.each(function () {
        var $this = $(this),
          $option = $('option', $this);
        if (!$option.length) {
          $this.prop('disabled', 'disabled');
        } else {
          $this.prop('disabled', false);
        }
      });
    });
  });
})(jQuery);
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    var hearingCenterLocatorComponent = $('.hearing-center-locator');
    var $theLang = $('html').attr('lang');
    if (hearingCenterLocatorComponent != 'undefined' && hearingCenterLocatorComponent.length > 0) {
      var initializeMap = function initializeMap() {
        getClinicsByCountry();
      };
      var getClinicsByLocation = function getClinicsByLocation() {
        locationCheck = true;
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(function (position) {
            window.DGS.Event.getInstance().trigger('trackHCLAllowLocation');
            var pos = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
            ACTION.push(22 + ' got location, pos: ' + pos);
            var marker = new google.maps.Marker({
              title: window.locationLabel,
              icon: defaultSettings.markers.geoMarker,
              position: pos,
              map: map
            });
            userLocation.ip = true;
            userLocation.lat = pos.lat();
            userLocation.lng = pos.lng();
            getClinicsByCoordinate(pos, true);
          }, function () {
            ACTION.push(25 + ' location aktive but not found');
          });
        }
      };
      var getClinicsByCountry = function getClinicsByCountry() {
        $('.search-field').val('');
        url = baseUrl + '/getclinics/' + getMarket() + '/' + $theLang;
        ;
        ACTION.push(35 + ' getClinicsByCountry, url: ' + url);
        clearMarkersLocal();
      };
      var getClinicsByCoordinate = function getClinicsByCoordinate(position, mapUpDate) {
        mapUpDate = mapUpDate || false;
        $('.search-field').val('');
        ACTION.push(41 + ' getClinicsByCoordinate, position: ' + position);
        var calPosition = position;
        /*for(var a = 0; a < calPosition.length; a++){
            while(calPosition[a] > 180)calPosition[a]-=180;
            while(calPosition[a] < -180)calPosition[a]+=180;
        }*/
        url = baseUrl + '/getclinicsbycoordinate/' + getMarket() + '/' + calPosition.lat() + "/" + position.lng();
        isListSearch = true;
        _getAllClinics(url, false, mapUpDate);
      };
      var getClinicsBySearch = function getClinicsBySearch(search) {
        if (consoleOn) console.log('getClinicsBySearch');
        url = baseUrl + '/getclinicsbysearch/' + getMarket() + '/' + search;
        ACTION.push(65 + ' getClinicsBySearch, url: ' + url);
        searchAktiv = true;
        isListSearch = true;
        _getAllClinics(url, false, true, 3);
        window.DGS.Event.getInstance().trigger('trackHCLSearch', search);
      }; // all webservice calls end here
      var _getAllClinics = function getAllClinics(url, singelton, mapUpDate, countstatus) {
        countstatus = countstatus || 0;
        singelton = singelton || false;
        mapUpDate = mapUpDate || false;
        ACTION.push(72 + ' getAllClinics, url: ' + url + ', singelton: ' + singelton + ', mapUpDate: ' + mapUpDate);
        clinics = null;
        setting = null;
        if (userLocation.ip) url = url + '/' + userLocation.lat + '/' + userLocation.lng; //  && !$thisSpot.hasClass('mobile-view')
        searchAktiv = false;
        $.ajax({
          url: url,
          type: 'GET',
          dataType: 'json',
          success: function success(data, textStatus, xhr) {
            $errorContainer.hide();
            $errorContainerTech.hide();
            // Updates the map settings, look markers and so on...
            if (!mapSetUpdated && data.Setting.MapConfiguration != '') {
              userLocation.unit = data.Setting.Unit;
              var mapSet = JSON.parse(data.Setting.MapConfiguration);
              defaultSettings = $.extend({}, defaultSettings, mapSet);
              mapSetUpdated = true;
              mapStyle = {
                gridSize: data.Setting.ClusterGridSize,
                maxZoom: data.Setting.ClusterMaxZoom,
                styles: [{
                  textColor: 'white',
                  height: 30,
                  url: defaultSettings.clusters.clusterSize1,
                  width: 30
                }, {
                  height: 40,
                  textColor: 'white',
                  url: defaultSettings.clusters.clusterSize2,
                  width: 40
                }, {
                  height: 50,
                  textColor: 'white',
                  url: defaultSettings.clusters.clusterSize3,
                  width: 50
                }, {
                  height: 70,
                  textColor: 'white',
                  url: defaultSettings.clusters.clusterSize4,
                  width: 70
                }, {
                  height: 90,
                  textColor: 'white',
                  url: defaultSettings.clusters.clusterSize5,
                  width: 90
                }],
                ignoreHidden: true
              };
            }
            //console.log(data.Setting);
            // Updates the selected clinic data...
            if (!singelton) {
              clinics = data.Clinics;
              if (consoleOn) console.log(clinics);
              setting = data.Setting;
              if (setting) {
                if (setting.Status == "ZERO_RESULTS") {
                  if (consoleOn) console.log("Error Occurred in search");
                  displayError();
                  return;
                } else if (setting.Status == "OVER_QUERY_LIMIT") {
                  if (countstatus != 0) {
                    countstatus--;
                    setTimeout(_getAllClinics(url, false, true, countstatus), 200);
                    return;
                  }
                }
              } else {
                if (consoleOn) console.log("tech error");
                displayErrorTech();
              }
              ACTION.push(121 + ' Updates the selected clinic data ', setting);
              if (mapUpDate) {
                ACTION.push(123 + ' set bounds ');
                if (setting.Bounds.ShowMap || setting.ShowMap) {
                  var pos = new google.maps.LatLng(setting.Bounds.SouthWest.Latitude, setting.Bounds.NorthEast.Longitude),
                    swb1 = setting.Bounds.SouthWest.Latitude,
                    swb2 = setting.Bounds.SouthWest.Longitude,
                    neb1 = setting.Bounds.NorthEast.Latitude,
                    neb2 = setting.Bounds.NorthEast.Longitude,
                    southWest = new google.maps.LatLng(swb1, swb2),
                    northEast = new google.maps.LatLng(neb1, neb2),
                    bounds = new google.maps.LatLngBounds(southWest, northEast);
                  ACTION.push(133 + ' drawMap ');
                  drawMap(setting.ZoomLevel, pos);
                  map.fitBounds(bounds);
                  mapUpDate = false;
                }
              }
              if (setting.Groups.length > 1 && firstRun) displayGroups();
              setUpClinicList(mapUpDate);
            } else {
              var singleClinicTemplate = $("#single-clinic-template").html();
              var txtUrl = data.Homepage;
              txtUrl = txtUrl.replace('https://', '').replace('http://', '');
              var phone = data.Phone;
              var bookLinkFormat = data.BookLink;
              var dist = data.DistanceToPoint.toFixed(1);
              var ut = userLocation.unit;
              if ($thisSpot.hasClass('mobile-view')) {
                dist = $('#' + data.Id + ' .clinic-distance').text();
                ut = '';
              }
              bookLinkFormat = bookLinkFormat.replace('https://', '').replace('http://', '');
              var clinicDiv = singleClinicTemplate.format(data.Id, data.Name, data.Address, data.PostalCode, data.City, data.Country, data.Region, data.Phone, data.Email, data.Homepage, txtUrl, data.BookLink, bookLinkFormat, data.Latitude, data.Longitude, dist, ut);
              ACTION.push(150 + ' info data ', data);
              $('.clinic-content', $thisSpot).each(function () {
                $(this).removeClass('open');
                $(this).find('.single-clinic').remove();
                if ($(this).is('#' + data.Id)) $(this).addClass('open');
              });
              if ($thisSpot.hasClass('mobile-view')) {
                $('#' + data.Id).append(clinicDiv).css('height', 'auto');
                showHideClass(phone == '', $('.clinic-phone', $thisSpot));
                showHideClass(bookLinkFormat == '', $('.clinic-booking', $thisSpot));
                if (!userLocation.ip) showHideClass(true, $('.clinic-distance', $thisSpot));
              } else {
                clearInfoWindow();
                var tempPos = new google.maps.LatLng(data.Latitude, data.Longitude);
                var infoWindow = new google.maps.InfoWindow({
                  map: map
                });
                infoWindow.setPosition(tempPos);
                infoWindow.setContent(clinicDiv);
                //map.setCenter(tempPos);
                infoWindows.push(infoWindow);
                infoWindowID = data.Id;
                google.maps.event.addListener(infoWindow, 'closeclick', function (e) {
                  resetMarkers(0);
                  clearInfoWindow();
                  $('.clinic-content', $thisSpot).each(function () {
                    $(this).removeClass('open');
                  });
                });
                google.maps.event.addListener(infoWindow, 'domready', function () {
                  showHideClass(phone == '', $('.clinic-phone', $thisSpot));
                  showHideClass(bookLinkFormat == '', $('.clinic-booking', $thisSpot));
                  var iwOuter = $('.gm-style-iw');
                  var iwBackground = iwOuter.prev();
                  iwBackground.css('top', '-10px');
                  iwBackground.children(':nth-child(1)').css({
                    'display': 'none'
                  });
                  iwBackground.children(':nth-child(3)').css({
                    'display': 'none'
                  });
                  if (!userLocation.ip) showHideClass(true, $('.clinic-distance', $thisSpot));
                });
              }
              // TRACKING
              var clinicTrackingName = data.Name + ' - ' + data.PostalCode;
              window.DGS.Event.getInstance().trigger('trackHCLClinicView', clinicTrackingName);
              setTimeout(function () {
                // TRACKING
                $('.clinic-phone a, a.clinic-hp, a.clinic-mail, .clinic-cta.button', $thisSpot).each(function () {
                  $(this).data('tracking-clinic-name', clinicTrackingName);
                  $(this).on('click', function () {
                    var $this = $(this),
                      clinicName = $this.data('tracking-clinic-name');
                    if ($this.hasClass('clinic-mail')) {
                      window.DGS.Event.getInstance().trigger('trackHCLClinicEmail', clinicName);
                    } else if ($this.hasClass('clinic-hp')) {
                      window.DGS.Event.getInstance().trigger('trackHCLClinicWWW', clinicName);
                    } else if ($this.hasClass('clinic-cta')) {
                      window.DGS.Event.getInstance().trigger('trackHCLClinicDriving', clinicName);
                    } else {
                      window.DGS.Event.getInstance().trigger('trackHCLClinicPhone', clinicName);
                    }
                  });
                });
                $('.clinic-cta.button', $thisSpot).on('click', function (e) {
                  e.preventDefault();
                  //var newStr = data.Name+','+data.Address+','+data.PostalCode+','+data.City+','+data.Country; //$('.clinic-content.open .clinic-name', $thisSpot).text()+', '+$('.clinic-content.open .clinic-address', $thisSpot).text();
                  //window.open("https://www.google.com/maps/dir//"+newStr, "_blank", "toolbar=no, scrollbars=no, resizable=yes, top=0, left=0, width=100%");
                  window.open("https://maps.google.com?daddr=" + $(this).attr('id'), "_blank", "toolbar=no, scrollbars=no, resizable=yes, top=0, left=0, width=100%");
                });
              }, 200);
            }
          },
          error: function error(xhr, textStatus, errorThrown) {
            console.log('error getting clinics', textStatus, errorThrown);
            //alert('An error occurred');
          }
        });
        //mapUpDate = false;
      };
      var displayGroups = function displayGroups() {
        if (!$(".radio-buttons", hearingCenterLocatorComponent).is(":visible")) {
          $(".radio-buttons", hearingCenterLocatorComponent).show();
          var buttonsTemplate = $("#radio-button-template").html();
          $.each(setting.Groups, function (index, group) {
            var theKey = "",
              theVal = "";
            $.each(group, function (ind, val) {
              if (ind == "key") theKey = val;
              if (ind == "value") theVal = val;
            });
            var button = buttonsTemplate.format(theKey, theVal);
            $('.radio-buttons').append(button);
          });
          $("#" + defaultGroup, hearingCenterLocatorComponent).prop('checked', 'checked');
        }
      };
      var showHideClass = function showHideClass(status, div) {
        status = status || false;
        if (status) {
          div.css('display', 'none');
        } else {
          div.css('display', 'block');
        }
      };
      var clearInfoWindow = function clearInfoWindow() {
        for (var i = 0; i < infoWindows.length; i++) {
          infoWindows[i].close();
          infoWindows = [];
        }
      };
      var setUpClinicList = function setUpClinicList(mapUpDate) {
        mapUpDate = mapUpDate || false;
        ACTION.push(166 + ' ! setUpClinicList ! mapUpDate:', mapUpDate, ' numberOfElements : ', numberOfElements);
        var clinicTemplate = $("#clinic-template").html();
        numberOfElements = clinics.length;
        if (numberOfElements == 0) numberOfElements = setting.ListCount;
        ACTION.push(170 + ' numberOfElements : ' + numberOfElements);
        var result = '';
        for (var i = 0; i < numberOfElements; i++) {
          var clinic = clinics[i];
          if (i < setting.ListCount) result += clinicTemplate.format(clinic.Name, clinic.FormattedAddress, clinic.Id, clinic.DistanceToPoint.toFixed(1), userLocation.unit, clinic.Group);
          if (setting.ShowMap && firstRun) {
            addMarkers(clinic.Latitude, clinic.Longitude, clinic.Name, clinic.Id, clinic.Group);
          }
        }
        if (mc != undefined && mc != null) mc.clearMarkers();
        if (mc == undefined || mc == null || defaultGroup == "" || defaultGroup == "None") mc = new MarkerClusterer(map, markersArray, mapStyle);
        ACTION.push(181 + ' mapStyle : ', mapStyle);
        $result.html(result);
        $('.clinic-content', $thisSpot).on('click', function (e) {
          if (e.target.tagName.toLowerCase() !== 'a') {
            var that = $(this);
            e.preventDefault();
            resetResult(that);
          }
        });
        //filter the list by default group if it exists
        if (defaultGroup != "" && defaultGroup != "None") {
          filterMarkers(defaultGroup);
          filterList(defaultGroup);
        }
        if (firstRun) {
          $('.radio-buttons input[type=radio]', $thisSpot).click(function () {
            defaultGroup = this.id;
            mc.clearMarkers();
            filterMarkers(this.id);
            filterList(this.id);
          });
          $('.search-icon', $thisSpot).on('click', function (e) {
            e.preventDefault();
            var w = $(this).width(),
              x = e.offsetX;
            resetList();
          });
          $('.search', $thisSpot).keypress(function (e) {
            if (e.keyCode == 13) {
              e.preventDefault();
              resetList();
            }
          });
          $('.search', $thisSpot).submit(function (e) {
            e.preventDefault();
            resetList();
          });
          $('.zoom.control > .zoom-in', $thisSpot).on('click', function () {
            var zno = Math.round(map.getZoom() + 1);
            map.setZoom(zno);
          });
          $('.zoom.control > .zoom-out', $thisSpot).on('click', function () {
            var zno = Math.round(map.getZoom() - 1);
            map.setZoom(zno);
          });
          firstRun = false;
          google.maps.event.addListener(map, 'idle', function () {
            //$('.hearing-center-locator .result').removeClass('hide');

            if (!locationCheck) getClinicsByLocation();
            if (!firstRun) {
              var newCenter = map.getCenter(),
                newLat = newCenter.lat(),
                newLng = newCenter.lng();
              ACTION.push(226 + ' !firstRun calls getAllClinics, newCenter: ' + newCenter + ', singelton: true');
              while (newLat > 180) newLat -= 180;
              while (newLat < -180) newLat += 180;
              while (newLng > 180) newLng -= 180;
              while (newLng < -180) newLng += 180;
              url = baseUrl + '/getclinicsbycoordinate/' + getMarket() + '/' + newLat + "/" + newLng;
              if (!isListSearch) {
                if (newLat != oldLat && newLng != oldLng) {
                  oldLat = newLat;
                  oldLng = newLng;
                  ACTION.push(235 + ' newLat : ' + url);
                  _getAllClinics(url, false, false);
                }
              } else {
                isListSearch = false;
              }
            }
            //resetResult($(this));
            ACTION.push(248 + ' getAllClinics, calPosition: ' + newCenter);
          });
        }
        ;
        $('.clinic-cta.button', $thisSpot).on('click', function (e) {
          e.preventDefault();
          //var newStr = $('.clinic-content.open .clinic-name', $thisSpot).text()+', '+$('.clinic-content.open .clinic-address', $thisSpot).text();
          window.open("https://maps.google.com?daddr=" + $(this).attr('id'), "_blank", "toolbar=no, scrollbars=no, resizable=yes, top=0, left=0, width=100%");
          //window.open("https://www.google.com/maps/dir//"+newStr, "_blank", "toolbar=no, scrollbars=no, resizable=yes, top=0, left=0, width=100%");
        });
        if (infoWindows.length > 0) $('.clinic-content' + '#' + infoWindowID).addClass('open');
        $result.scrollTop(0);
        $('.clinic-cta.button', $thisSpot).on('click', function (e) {
          e.preventDefault();
          //var newStr = $('.clinic-content.open .clinic-name', $thisSpot).text()+', '+$('.clinic-content.open .clinic-address', $thisSpot).text();
          //window.open("https://www.google.com/maps/dir//"+newStr, "_blank", "toolbar=no, scrollbars=no, resizable=yes, top=0, left=0, width=100%");
          window.open("https://maps.google.com?daddr=" + $(this).attr('id'), "_blank", "toolbar=no, scrollbars=no, resizable=yes, top=0, left=0, width=100%");
        });
        if (!userLocation.ip) showHideClass(true, $('.clinic-distance', $thisSpot));
      };
      var resetList = function resetList() {
        var query = $('.search-field', $thisSpot).val();
        if (query != '' && query != null & query != undefined) getClinicsBySearch(query);
      };
      var displayError = function displayError() {
        $errorContainer.show();
      };
      var displayErrorTech = function displayErrorTech() {
        $errorContainerTech.show();
      };
      var addMarkers = function addMarkers(latitude, longitude, name, id, group) {
        if (consoleOn) console.log("add markers defaultGroup " + defaultGroup);
        var pos = new google.maps.LatLng(latitude, longitude);
        var marker = new google.maps.Marker({
          position: pos,
          map: map,
          title: name,
          icon: defaultSettings.markers.defaultMarker,
          id: id
        });
        marker.group = group;
        markersArray.push(marker);
        google.maps.event.addListener(marker, 'click', function () {
          resetResult($(this));
        });
        google.maps.event.addListener(marker, 'visible_changed', function () {
          if (marker.getVisible()) {
            mc.addMarker(marker, true);
          } else {
            mc.removeMarker(marker);
          }
        });
      };
      var filterMarkers = function filterMarkers(group) {
        if (consoleOn) console.log("Applying marker filter for group " + group);
        for (var i = 0; i < markersArray.length; i++) {
          if (markersArray[i].group == group) {
            markersArray[i].setVisible(true);
          } else {
            markersArray[i].setVisible(false);
          }
        }
        mc.redraw();
        clearInfoWindow();
      };
      var filterList = function filterList(group) {
        if (consoleOn) console.log("Applying list filter for group " + group);
        $('.clinic-content', $thisSpot).each(function () {
          if ($(this)) {
            if (!$(this).hasClass(group)) {
              $(this).hide();
            } else {
              $(this).show();
            }
          }
        });
      };
      var resetResult = function resetResult(that) {
        if (that.hasClass('clinic-content')) {
          if (that.hasClass('open')) {
            that.removeClass('open');
            if ($thisSpot.hasClass('mobile-view')) {
              that.find('.single-clinic').remove();
            }
            resetMarkers(0);
            clearInfoWindow();
          } else {
            var mID = that.attr('id');
            resetMarkers(mID);
          }
        } else {
          var mID = that.attr('id');
          resetMarkers(mID);
        }
      };
      var resetMarkers = function resetMarkers(id) {
        for (var a = 0; a < markersArray.length; a++) {
          markersArray[a].setIcon(defaultSettings.markers.defaultMarker);
          if (markersArray[a].id == id) markersArray[a].setIcon(defaultSettings.markers.selectedMarker);
        }
        setDetails(id);
      };
      var clearMarkersLocal = function clearMarkersLocal() {
        for (var i = 0; i < markersArray.length; i++) {
          markersArray[i].setMap(null);
        }
        markersArray = [];
        ACTION.push(345 + ' HHHHHHHHHHHHHHHHHHH clearMarkersLocal markersArray: ', markersArray);
        markersArray = new Array();
        firstRun = true;
        isListSearch = true;
        _getAllClinics(url, false, true);
        if (mc != undefined) mc.clearMarkers();
      };
      var drawMap = function drawMap(zoom, pos) {
        zoom = zoom || 4;
        ACTION.push(353 + ' DRAWMAP, zoom: ' + zoom + ' pos: ' + pos + ' map: ' + map);
        if (map == undefined) {
          //if(noScrollWheel){
          var mapOptions = {
            scrollwheel: false,
            zoom: 4,
            center: pos,
            disableDefaultUI: true,
            zoomControlOptions: {
              position: google.maps.ControlPosition.RIGHT_TOP,
              style: google.maps.ZoomControlStyle.SMALL
            }
          };
          /*}else{
              var mapOptions = {
                  scrollwheel: true,
                  zoom: 4,
                  center: pos,
                  disableDefaultUI: true,
                  zoomControlOptions: {
                      position    :   google.maps.ControlPosition.RIGHT_TOP,
                      style       :   google.maps.ZoomControlStyle.SMALL
                  },
              };
          }*/
          map = new google.maps.Map(document.getElementById('map-canvas'), mapOptions);
          map.set('styles', defaultSettings.googlemap);
        }
      };
      var getMarket = function getMarket() {
        return $thisSpot.data('configuration');
      };
      var tracer = function tracer() {
        if (consoleOn) console.log(actions);
      };
      var setDetails = function setDetails(id) {
        url = baseUrl + '/getclinicdata/' + getMarket() + '/' + id;
        ACTION.push(393 + ' setDetails, url: ' + url);
        isListSearch = true;
        if (id != 0) _getAllClinics(url, true, false);
      };
      $('#market-list').change(function () {
        getClinicsByCountry();
      });
      var consoleOn = false,
        isListSearch = false,
        firstRun = true,
        country = 'us.json',
        testData = [country, 'ip.json', 'manhatten.json'],
        clinics = null,
        setting = null,
        oldLng = '',
        oldLat = oldLng,
        url = oldLat,
        locationCheck = false;
      var actionsListen = function actionsListen() {
        var ACTION = [];
        ACTION.push = function () {
          if (consoleOn) console.log("Action", arguments);
          return Array.prototype.push.apply(this, arguments);
        };
        return ACTION;
      };
      var ACTION = new actionsListen();
      var defaultSettings = {
        markers: {
          defaultMarker: '~/media/oticon/main/mockup/oticon-marker.png',
          selectedMarker: '~/media/oticon/main/mockup/oticon-marker-aktiv.png',
          geoMarker: '~/media/oticon/main/mockup/oticon-marker-home.png'
        },
        clusters: {
          clusterSize1: '~/media/oticon/main/mockup/m1.png',
          clusterSize2: '~/media/oticon/main/mockup/m2.png',
          clusterSize3: '~/media/oticon/main/mockup/m3.png',
          clusterSize4: '~/media/oticon/main/mockup/m4.png',
          clusterSize5: '~/media/oticon/main/mockup/m5.png'
        },
        googlemap: [{
          featureType: 'road.highway',
          elementType: 'geometry',
          stylers: [{
            color: '#FFFFFF'
          }, {
            weight: 1
          }]
        }, {
          featureType: 'road.highway',
          elementType: 'labels',
          stylers: [{
            visibility: 'off'
          }]
        }]
      };
      var map,
        searchAktiv = false,
        infoWindows = [],
        infoWindowID = 0,
        baseUrl = '/webservices/hcl.svc',
        numberOfElements = 0,
        markersArray = [],
        noScrollWheel = false,
        mapSetUpdated = false,
        userLocation = {
          ip: false,
          lat: 0,
          lng: 0,
          unit: 'KM'
        },
        $errorContainer = $('.no-results-message'),
        $errorContainerTech = $('.technical-error-message'),
        $thisSpot = $('.hearing-center-locator'),
        $result = $('.result', $thisSpot),
        mc,
        defaultGroup = $('.radio-buttons', $thisSpot).data("default-group");
      var mapStyle = {};
      if (consoleOn) console.log("defaultGroup " + defaultGroup);
      if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) noScrollWheel = true;
      if (noScrollWheel || window.matchMedia("(max-width: 800px)").matches) {
        $result.addClass('mobile-view');
        $thisSpot.addClass('mobile-view');
      }
      window.DGS.Resizer.getInstance().register(function () {
        if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.matchMedia("(max-width: 800px)").matches) {
          $result.addClass('mobile-view');
          $thisSpot.addClass('mobile-view');
        } else if (!noScrollWheel) {
          $result.removeClass('mobile-view');
          $thisSpot.removeClass('mobile-view');
        }
      });
      if ((typeof google === "undefined" ? "undefined" : _typeof(google)) === 'object' && _typeof(google.maps) === 'object') {
        //google.maps.event.addDomListener(window, 'load', );
        if (hearingCenterLocatorComponent.hasClass("hide")) {
          var observer = new MutationObserver(function () {
            if (!hearingCenterLocatorComponent.hasClass("hide")) {
              initializeMap();
              observer.disconnect();
            }
          });
          observer.observe(hearingCenterLocatorComponent[0], {
            attributeFilter: ['class']
          });
        } else {
          initializeMap();
        }
      }
    }
  });
})(jQuery);
(function ($) {
  var printMarkup = "<h1>Print this</h1>";
  window.DGS.Event.getInstance().on('trackHCLClinicView', function () {
    setTimeout(function () {
      // wait for google maps to inject to clinic template html
      $('#wrapper .hearing-center-locator').each(function () {
        var $spot = $(this),
          $clinic = $('.single-clinic', $spot),
          pageUrl = $spot.data('print-page'),
          $printBtn = $('.print-btn', $clinic);
        if (pageUrl && !$printBtn.length) {
          // if printbtn is not there already, add it
          $clinic.prepend('<div class="print-btn"></div>');
          // update print btn variable
          $printBtn = $('.print-btn', $clinic);
          // Add click handler
          $printBtn.click(function () {
            var w = window.open(pageUrl + "?id=" + $clinic.attr('id'), 'printWin');
            //$(w.document.body).html(printMarkup);
          });
        } else if (!pageUrl && $printBtn.length) {
          $printBtn.remove();
        }
      });
    }, 200);
  });
  window.DGS.OnLoad.getInstance().register(function () {
    $('#wrapper .hearing-center-locator').each(function () {
      //display the link above search for Medical
      $('.map-link', $(this)).show();
    });
    $('#wrapper .hearing-centor-locator-print').each(function () {
      $('.print-btn-container').click(function () {
        window.print();
      });
    });
    window.DGS.Resizer.getInstance().register(function () {
      $('#wrapper .hearing-center-locator').each(function () {
        //recalculate results list max-height
        setTimeout(function ($this) {
          var resultHeight = $('.component-content', $this).height() - $('.map-top-nav', $this).height();
          $('.result', $this).css('max-height', resultHeight);
        }, 100, $(this));
      });
    });
  });
})(jQuery);
/**
 * Created by mady on 12-07-2016.
 * Note. DEPRECATED. Use the Data hierarchy spot variation named data-list-search
 */

(function ($, DGS) {
  var DataListSearchSpot = DGS.DataListSearchSpot || {};
  DataListSearchSpot.getConfigForDataListSearchSpot = function ($dataListSearchSpot) {
    var configId = $dataListSearchSpot.data("configuration"),
      configText = $("script[data-configuration=" + configId + "]").html();
    try {
      return JSON.parse(configText);
    } catch (error) {
      return {};
    }
  };
  DGS.OnLoad.getInstance().register(function () {
    $("#wrapper > #content .component.data-list-search-spot").each(function () {
      var $dataListSearchSpot = $(this),
        blockSearchTemp = false,
        config = DataListSearchSpot.getConfigForDataListSearchSpot($dataListSearchSpot),
        $advSearchToggle = $('.data-list-search-spot__adv-search-toggle'),
        $advSearchContainer = $('.data-list-search-spot__adv-search-outer-container'),
        querySearch = DGS.getLocationHash().toLowerCase(),
        setBlockSearchTemp = function setBlockSearchTemp() {
          blockSearchTemp = true;
          setTimeout(function () {
            blockSearchTemp = false;
          }, 500);
        },
        basicSearchTerm = function (querySearch) {
          return querySearch.indexOf(':') == -1 ? querySearch : null;
        }(querySearch),
        advSearchFields = function (querySearch) {
          if (querySearch.indexOf(':') > -1) {
            var searchParts = querySearch.split('/'),
              searchObj = {},
              keyValue,
              key,
              value;
            searchParts.forEach(function (searchPart) {
              keyValue = searchPart.split(':');
              key = keyValue[0];
              value = keyValue[1];
              if (!searchObj.hasOwnProperty(key)) {
                searchObj[key] = [];
              }
              searchObj[key].push(value);
            });
            return searchObj;
          }
          return null;
        }(querySearch),
        openAdvSearch = function openAdvSearch() {
          $advSearchToggle.addClass('open');
          $advSearchContainer.addClass('open');
          $('.data-list-search-spot__query', $dataListSearchSpot).prop('disabled', true);
          var position = $('.data-list-search-spot__adv-search-outer-container').position(),
            top = position ? position.top : null;
          if (top != null) {
            //window.scrollTo(0, );
            TweenMax.to(window, 0.3, {
              scrollTo: {
                x: 0,
                y: top - 6 * parseFloat(getComputedStyle(document.body).fontSize)
              }
            });
          }
        },
        closeAdvSearch = function closeAdvSearch() {
          $advSearchToggle.removeClass('open');
          $advSearchContainer.removeClass('open');
          $('.data-list-search-spot__query', $dataListSearchSpot).prop('disabled', false);
        };

      // TOGGLE ADV / SIMPLE SEARCH
      $advSearchToggle.click(function () {
        var $this = $(this);
        if (!$this.hasClass('open')) {
          openAdvSearch();
        } else {
          closeAdvSearch();
        }
      });

      // FREE SEARCH QUERY
      $('.data-list-search-spot__query', $dataListSearchSpot).keyup(function (e) {
        if (blockSearchTemp) return;
        if (e.keyCode == 13) {
          setBlockSearchTemp();
          DGS.Event.getInstance().trigger(DGS.DataListSpotConfig.Event.DATA_REQUEST_BUILD_QUERY, $dataListSearchSpot, config.endpoint);
        }
      });

      // ADV SEARCH
      $('.data-list-search-spot__adv-search-input', $dataListSearchSpot).keyup(function (e) {
        if (blockSearchTemp) return;
        if (e.keyCode == 13) {
          setBlockSearchTemp();
          DGS.Event.getInstance().trigger(DGS.DataListSpotConfig.Event.DATA_REQUEST_BUILD_ADV_QUERY, $dataListSearchSpot, config.endpoint);
        }
      });
      $('.data-list-search-spot__adv-search-btn-container a', $dataListSearchSpot).click(function (event) {
        if (blockSearchTemp) return;
        event.preventDefault();
        setBlockSearchTemp();
        DGS.Event.getInstance().trigger(DGS.DataListSpotConfig.Event.DATA_REQUEST_BUILD_ADV_QUERY, $dataListSearchSpot, config.endpoint);
      });

      // BUILD SELECT GROUP
      $('.data-list-search-spot__adv-search-select-group', $dataListSearchSpot).each(function () {
        var allLabel = "All",
          allContent = [allLabel],
          $this = $(this),
          $selects = $('.custom-select-container', $this),
          level1Endpoint = "/webservices/assortment.svc/getcategories",
          level2Endpoint = "/webservices/assortment.svc/getmodels",
          level3Endpoint = "/webservices/assortment.svc/getstyles",
          $level1Select = $($selects[0]),
          $level2Select = $($selects[1]),
          $level3Select = $($selects[2]),
          getContent = function getContent(endpoint, callback) {
            $.ajax({
              url: endpoint,
              type: 'GET'
            }).then(function (data, textState, jqXHR) {
              callback.apply(null, [data]);
            }, function (jqXHR, textStatus, msg) {
              callback.apply(null, [[]]); // returning empty array on error
            });
          },
          resetControl = function resetControl() {
            $level2Select.customSelectOptions(allContent);
            $level2Select.customSelectSetValue(0);
            $level2Select.customSelectDisabled(true);
            $level3Select.customSelectOptions(allContent);
            $level3Select.customSelectSetValue(0);
            $level3Select.customSelectDisabled(true);
          };
        var populateLevel1 = function populateLevel1() {
            getContent(level1Endpoint, function (content) {
              $level1Select.customSelectOptions(allContent.concat(content));
              $level1Select.customSelectSetValue(0);
            });
          },
          populateLevel2 = function populateLevel2(level1) {
            getContent(level2Endpoint + "/" + level1, function (content) {
              $level2Select.customSelectOptions(content);
              $level2Select.customSelectSetValue(0);
            });
          },
          populateLevel3 = function populateLevel3(level1, level2) {
            getContent(level3Endpoint + "/" + level1 + "/" + level2, function (content) {
              $level3Select.customSelectOptions(content);
              $level3Select.customSelectSetValue(0);
            });
          };
        $level1Select.customSelectChange(function () {
          var levelValue = $level1Select.customSelectGetValue();
          if (levelValue == allLabel) {
            resetControl();
            return;
          }
          populateLevel2(levelValue);
          $level2Select.customSelectDisabled(false);
        });
        $level2Select.customSelectChange(function () {
          populateLevel3($level1Select.customSelectGetValue(), $level2Select.customSelectGetValue());
          $level3Select.customSelectDisabled(false);
        });
        populateLevel1();
        resetControl();
      });

      // HANDLE URL QUERY STRING SEARCH
      if (basicSearchTerm) {
        $('.data-list-search-spot__query', $dataListSearchSpot).val(basicSearchTerm);
        DGS.Event.getInstance().trigger(DGS.DataListSpotConfig.Event.DATA_REQUEST_INITIAL_BUILD_QUERY, $dataListSearchSpot, config.endpoint);
      } else if (advSearchFields) {
        openAdvSearch();
        $('*[class*=field]').each(function () {
          var $input = $(this);
          var classes = $input.attr('class').toLowerCase().split(' '),
            value;
          classes.forEach(function (className) {
            if (className.indexOf('field-') == 0) {
              className = className.substr(6);
              if (advSearchFields.hasOwnProperty(className)) {
                value = advSearchFields[className].shift();
                if (advSearchFields[className].length == 0) {
                  delete advSearchFields[className];
                }
                $input.val(value);
              }
            }
          });
        });
        DGS.Event.getInstance().trigger(DGS.DataListSpotConfig.Event.DATA_REQUEST_INITIAL_BUILD_ADV_QUERY, $dataListSearchSpot, config.endpoint);
      }
    });
  });
  DGS.DataListSearchSpot = DataListSearchSpot;
})(jQuery, window.DGS);
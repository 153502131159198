(function ($) {
  "use strict";

  window.DGS.OnLoad.getInstance().register(function () {
    var $copyright = $($("#footer .component.rich-text")[0]);
    $copyright.html($copyright.html().replace(/2019|2020|2021|2022|2023|2024|2025|2026|2027/gi, new Date().getFullYear()));
    var $bottomLinks = $($("#footer .component.rich-text")[1]),
      $textList = $("#footer .component.rich-text-list"),
      footerSettings = {
        mainContainer: $("#wrapper > #footer"),
        sections: {
          desktop: {
            containers: [{
              className: "footer-container",
              containers: [{
                className: "row-1",
                components: [$textList]
              }, {
                className: "row-2",
                components: [$copyright]
              }, {
                className: "row-3",
                components: [$bottomLinks]
              }]
            }]
          }
        }
      };
    window.DGS.Builder.getInstance().build(footerSettings);
  });
})(jQuery);
/* eslint-disable func-names */
(function ($) {
  var socialSharingConfig = {
    layout: 'float',
    serviceElementsHeaderClass: 'service-elements-header',
    serviceElementClass: 'service-element',
    shareWindowDimensions: 'width=626,height=436',
    restrictBoundariesSelectors: {
      top: '.header-desktop-top',
      // so it does not go ABOVE the header
      bottom: '#footer' // Or BELOW the footer.
    }
  };
  window.DGS.OnLoad.getInstance().register(function () {
    $('#social-sharing').sharingTools(socialSharingConfig);
    window.DGS.Resizer.getInstance().register(function () {
      var $shareComponent = $('#social-sharing');
      if ($shareComponent.length) {
        // if sharecomponent is placed at bottom
        if ($shareComponent.offset().top + $shareComponent.height() >= $(window).height()) {
          if ($shareComponent.hasClass('float')) {
            var socialSharingHeight = $shareComponent.outerHeight();
            $('#footer').css('margin-bottom', socialSharingHeight);
          }
        }
      }
    });
  });
})(jQuery, window.DGS);
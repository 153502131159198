import React from 'react';
import WDHResourceCenter, { FilterViewConfig, ListItemViewConfig, RESOURCE_CENTER_FILTER_UPDATED } from '@demant/resource-center';
import { onEvent } from '@demant/wdh';
FilterViewConfig.expandFiltersOnLoad = true;
FilterViewConfig.hideToggleButton = true;
ListItemViewConfig.useAnimationDelayOffset = true;
var resourceCenter = new WDHResourceCenter(".resource-center:not(.grid-layout)");
resourceCenter.run();
onEvent(RESOURCE_CENTER_FILTER_UPDATED, function (filters) {
  if (filters.length !== 5) {
    // this checks if there are 5 filters, and makes sure the code doesn't run if there aren't
    // on US site there are less than 5 filters since the Solutions BAHS/CI filter is not there, so below code shouldn't run
    return;
  }
  var bahsCheckbox = document.querySelector('.filters .collection:nth-child(1) .collection-element:nth-child(1) input');
  var ciCheckbox = document.querySelector('.filters .collection:nth-child(1) .collection-element:nth-child(2) input');
  var bahsCheckboxClicked = filters[0].elements[0].selected;
  var ciCheckboxClicked = filters[0].elements[1].selected;
  var bahsFilterList = document.querySelector('.filters .collection:nth-child(3)');
  var ciFilterList = document.querySelector('.filters .collection:nth-child(2)');
  if (bahsCheckboxClicked && bahsFilterList.classList.contains('hide')) {
    bahsFilterList.classList.remove('hide');
    resourceCenter.updateFilter({
      id: ciCheckbox.value,
      selected: false
    });
    return;
  }
  if (ciCheckboxClicked && ciFilterList.classList.contains('hide')) {
    ciFilterList.classList.remove('hide');
    resourceCenter.updateFilter({
      id: bahsCheckbox.value,
      selected: false
    });
    return;
  }
  if (bahsCheckboxClicked && !ciCheckboxClicked) {
    bahsFilterList.classList.remove('hide');
    ciFilterList.classList.add('hide');
  } else if (ciCheckboxClicked && !bahsCheckboxClicked) {
    ciFilterList.classList.remove('hide');
    bahsFilterList.classList.add('hide');
  } else {
    ciFilterList.classList.remove('hide');
    bahsFilterList.classList.remove('hide');
  }
});